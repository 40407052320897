<template>
  <div class="buy_box">
    <div class="buy_left">
      <div class="buy_head">
        <div class="buy_head_left"><img :src="avatar" /></div>
        <div class="buy_head_right">
          <div class="buy_head_right_top">
            <span>{{ userInfo.username }}</span>

            <!-- <div>
							<img src="../assets/images/viplogo/type_one_v.svg"
								v-if="userInfo.video_vip == 1 && dealClassStyle(userInfo.video_end_time)==1">
							<img src="../assets/images/viplogo/type_one_v.svg" v-else>
							<img src="../assets/images/viplogo/type_one_v.svg"
								v-if="userInfo.down_vip == 1 && dealClassStyle(userInfo.down_end_time)==1">
							<img src="../assets/images/viplogo/type_one_v.svg" v-else>
						</div> -->
          </div>
          <!-- <div class="buy_head_right_bottom">
						<img v-if="userInfo.video_vip == 1 && userInfo.down_vip == 1" src="../assets/images/viplogo/vip.png" />
						<img v-else src="../assets/images/viplogo/ordinary.png" alt="">
					</div> -->
        </div>
      </div>
      <div class="buy_list">
        <div>会员专属特权</div>
        <ul>
          <li>字体素材下载</li>
          <li>字体素材商用授权</li>
          <li>版权保障</li>
          <li>畅学海量系统教程</li>
          <li>教程源文件及素材下载</li>
          <li>APP在线学习</li>
          <li>作业100%点评</li>
          <li>优秀作业奖励</li>
          <li>新增教程不限次学</li>
        </ul>
      </div>
      <!-- <div class="buy_quanyi">
				查看会员权益对比>
			</div> -->
    </div>
    <div class="buy_rights">
      <div class="buy_close">
        <div class="el-icon-close" @click="close"></div>
      </div>
      <div class="buy_price">
        <!-- <img src="../assets/images/viplogo/price.png" /> -->
        <div
          :class="type == 6 ? 'buy_price_one check' : 'buy_price_one'"
          @click="typeFun(6)"
        ></div>
        <div
          :class="type == 10 ? 'buy_price_two check2' : 'buy_price_two'"
          @click="typeFun(10)"
        ></div>
      </div>

      <div class="buy_code">
        <div class="buy_code_text">
          <span class="text">使用优惠券</span>
          <template v-if="coupon_id != 0">
            <span
              class="text_red"
              @click="couponStatusFun"
              style="cursor: pointer"
              ><span class="samll">-￥</span>{{dec_money}}</span
            >
          </template>
          <template v-else>
            <span class="buy_use" @click="couponStatusFun"
              >{{ coupon_list?coupon_list.length:0 }}张可用</span
            >
          </template>

          <span class="text">应付金额</span
          ><span class="text_red"
            ><span class="samll">￥</span>{{ pirce }}</span
          >
          <span v-if="inviteId" style="margin-left: 30px;color: #999;">邀请购买会员费用立减{{invite_price}}元</span>
        </div>
        <div class="wx_icon"><img src="../assets/images/vip/vx.png" /></div>
        <div class="ali_icon"><img src="../assets/images/vip/zfb.png" /></div>
        <div class="wx"><img :src="code_url_wx" /></div>
        <div class="ali"><img :src="code_url_al" /></div>
        <div class="buy_code_wrap" v-show="check == false">
          <img src="../assets/images/vip/gantan.png" />
          请阅读并勾选《会员服务协议》和《授权许可协议》
        </div>
      </div>
      <div class="buy_text">
        <div class="buy_text_left">
          <span @click="jumpFapiao">开具发票</span>
          <span class="colorf8 padd">|</span>
          <el-checkbox v-model="check">同意</el-checkbox>
          <!-- <a class="check">同意</a> -->
          <span @click="seeShare(7)">《会员服务协议》</span>
          <span class="colorf8">和</span>
          <span @click="toAgreement(6)">《授权许可协议》</span>
        </div>
        <div class="buy_text_right">
          支付遇到问题？ <span @click="contactqq">联系客服</span>
        </div>
      </div>
    </div>
    <div class="bg" v-if="coupon_status"></div>
    <div class="coupon_wrap" v-if="coupon_status">
      <div class="coupon">
        <h2>优惠券</h2>
        <div class="coupon_content">
          <ul v-if="coupon_list && coupon_list.length > 0">
            <li v-for="(item, index) of coupon_list" :key="index">
              <div class="price">
                <span>￥</span><span>{{ item.amount }}</span>
              </div>
              <div class="coupon_name">
                <div>{{ item.title }}</div>
                <div>有效期至：{{ item.lifespan }}</div>
              </div>
              <div class="cut"></div>
              <div class="coupon_use" @click="useCouponFun(item.id,item.amount)">
                立即使用
              </div>
            </li>
          </ul>
          <el-empty description="暂无优惠券" v-else></el-empty>
        </div>
        <div class="close" @click="couponStatusFun">
          <img src="../assets/images/close.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["type"],
  data() {
    return {
      typeIndex: 0,
      info: {},
      erweima1: require("../assets/images/erweima@2x.png"),
      erweima2: require("../assets/images/erweima@2x.png"),
      userInfo: {},
      avatar: "",
      code_url_al: "",
      code_url_wx: "",
      pirce: "",
      check: false,
      coupon_list: [],
      coupon_id: 0,
      coupon_status: false,
      dec_money:'',
      invite_price: 0,
      inviteId:localStorage.getItem('inviteId')
    };
  },
  mounted() {
    if (localStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));

      if (this.userInfo.thumb.indexOf("http") == -1) {
        this.avatar = require("../assets/images/head.svg");
      } else {
        this.avatar = this.userInfo.thumb;
      }
    }
    this.listFun();
    this.qrcodeFun(this.type);
    this.inviteFun()
  },
  methods: {
    close() {
      this.$parent.closeDrawer();
      this.$emit("toclearFun", 1);
    },
    useCouponFun(id,money) {
      this.coupon_id = id;
      this.coupon_status = false;
      this.dec_money = money;
      this.qrcodeFun(this.type);
    },
    listFun() {
      this.$api.POST(
        this.$face.couponRecord,
        {
          site_id: this.type,
        },
        (res) => {
          this.coupon_list = res.data.coupon;
        }
      );
    },
    dealClassStyle(time) {
      let timeString = new Date(time.substr(0, 10)).getTime();
      let nowTimeString = new Date().getTime();
      if (timeString < nowTimeString) {
        //过期时间
        return 2;
      } else {
        return 1;
      }
    },
    jumpFapiao() {
      //this.$parent.closeDrawer();
      // this.$router.push({
      // 	name: "userOrder",
      // 	query: {
      // 		type: 2
      // 	}
      // })
      let routeUrl = this.$router.resolve({
        name: "userOrder",
        query: {
          type: 2,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    couponStatusFun() {
      this.coupon_status = !this.coupon_status;
    },
    typeFun(type) {
      this.$emit("typeFun", type);
      this.type = type;
      this.coupon_id = 0;
      this.qrcodeFun(type);
    },
    // 获取支付二维码
    qrcodeFun(id) {
      var that = this;
      that.code_url_al = require('../assets/images/loading.gif');
      that.code_url_wx = require('../assets/images/loading.gif');
      this.$api.POST(
        this.$face.member_orderQrcode,
        {
          site_id: id,
          coupon_id: this.coupon_id == 0 ? "" : this.coupon_id,
          invite:localStorage.getItem('inviteId')!=localStorage.getItem('userId')?localStorage.getItem('inviteId'):''
        },
        function (res) {
          that.code_url_al = res.data.code_url_al;
          that.code_url_wx = res.data.code_url_wx;
          that.pirce = res.data.amount_payable;
          that.coupon_list = res.data.coupon;
          // 订单编号
          //localStorage.setItem('order_no', res.data.order_no);
          //that.$parent.order_no = res.data.order_no
          that.$emit("orderinfo", res.data.order_no);
        }
      );
    },

    // 获取优惠价格
    inviteFun() {
      var that = this;
      this.$api.POST(
        this.$face.invite_record_reduction,'',
        function (res) {
          that.invite_price = res.data.price;
        }
      );
    },

    toAgreement(id) {
      //this.$parent.closeDrawer();
      // this.$router.push({
      // 	name: "userAgreement",
      // 	query: {
      // 		id: id
      // 	}
      // })
      let routeUrl = this.$router.resolve({
        name: "userAgreement",
        query: {
          id: id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },

    seeShare(id) {
      let routeUrl = this.$router.resolve({
        path: "/agreement",
        query: {
          id: id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    contactqq() {
      window.open(
        "http://dct.zoosnet.net/LR/Chatpre.aspx?id=DCT52374225&lng=cn"
      );
    },
  },
};
</script>

<style>
.buy_box {
  width: 956px;
  height: 597px;
  background-color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buy_left {
  width: 215px;
  background: #fff;
  padding-left: 28px;
  padding-top: 22px;
  float: left;
  height: 575px;
}

.buy_left .buy_head {
  height: 55px;
}

.buy_left .buy_head .buy_head_left {
  float: left;
  width: 55px;
  height: 55px;
}

.buy_left .buy_head .buy_head_left img {
  width: 55px;
  height: 55px;
  border-radius: 100%;
}

.buy_head_right {
  width: 135px;
  float: left;
  margin-left: 10px;
  line-height: 42px;
}

.buy_head_right_top {
  display: block;
  width: 135px;
  margin-top: 6px;
  margin-bottom: 5px;
  overflow: hidden;
}

.buy_head_right_top span {
  float: left;
  font-size: 18px;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 80px;
}

.buy_head_right_top > div {
  float: left;
  margin-left: 5px;
}

.buy_head_right_top > div img {
  width: 20px;
  height: 20px;
  float: left;
}

.buy_head_right_bottom {
  display: block;
  clear: both;
}

.buy_list {
  margin-top: 37px;
}

.buy_list > div {
  font-size: 16px;
  font-weight: bold;
}

.buy_list div {
  color: #595757;
}

.buy_list ul {
  margin-top: 13px;
  color: #636262;
}

.buy_list li {
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  background: url(../assets/images/vip/emo.png) no-repeat;
  background-position: left center;
  padding-left: 21px;
  letter-spacing: 0.5px;
  color: #b5b5b6;
}

.buy_quanyi {
  width: 194px;
  height: 49px;
  background: #f6f6f6;
  border-radius: 10px;
  text-align: center;
  line-height: 49px;
  margin-top: 92px;
  color: #8c8c8c;
  cursor: pointer;
}

.buy_rights {
  width: 647px;
  float: right;
  background: #f6f6f6;
  height: 597px;
  padding: 0 33px;
}

.buy_close {
  height: 38px;
  text-align: right;
  font-size: 22px;
  color: #a0a1a1;
  margin-top: 15px;
  line-height: 38px;
  cursor: pointer;
}

.buy_code {
  width: 100%;
  height: 263px;
  background: #fff;
  margin-top: 17px;
  position: relative;
  border-radius: 10px;
}
.buy_code .wx,
.buy_code .ali {
  position: absolute;
  width: 161px;
  height: 162px;
  background-image: url("../assets/images/vip/frame.png");
  text-align: center;
}
.buy_code img {
  width: 150px;
  height: 150px;
  margin: 6px auto;
}

.buy_code .wx {
  left: 133px;
  top: 62px;
}

.buy_code .ali {
  right: 133px;
  top: 62px;
}

.buy_text {
  font-size: 12px;
  margin-top: 13px;
}

.buy_text span {
  color: #4f4eff;
  cursor: pointer;
  float: left;
}

.buy_text .buy_text_left {
  float: left;
}

.buy_text .buy_text_right {
  float: right;
}
.buy_text .buy_text_right span {
  float: none;
}

.buy_price {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.buy_price > div {
  width: 315px;
  height: 196px;
}
.buy_price_one {
  background-image: url("../assets/images/vip/video_vip.png");
  background-repeat: no-repeat;
  float: left;
}
.buy_price_two {
  background-image: url("../assets/images/vip/all_vip.png");
  background-repeat: no-repeat;
  float: right;
}
.buy_price .check {
  background-image: url("../assets/images/vip/video_vip_check.png");
}
.buy_price .check2 {
  background-image: url("../assets/images/vip/all_vip_check.png");
}

.buy_price img {
  max-width: 100%;
  max-height: 100%;
}
.buy_text .el-checkbox {
  float: left;
}
.buy_text .el-checkbox__label {
  padding-left: 3px !important;
  color: #333;
  font-size: 12px;
  line-height: 1;
}
.buy_text .el-checkbox__inner:hover {
  border-color: #409eff !important;
}

.buy_text .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #409eff !important;
  border-color: #409eff !important;
}
.buy_text .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #333 !important;
}
.colorf8 {
  color: #444 !important;
  cursor: auto !important;
}
.padd {
  margin: 0 5px;
  margin-top: -1px;
}
.buy_code_wrap {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 220px;
  height: 165px;
  background: #fff;
  box-shadow: rgb(0 0 0 / 12%) 0px 0px 8px 7px;
  text-align: center;
  color: #444;
  padding: 0 40px;
  line-height: 24px;
  color: #a5a6a6;
}
.buy_code_wrap img {
  width: 44px;
  position: initial;
  height: 43px;
  display: block;
  margin: 23px auto;
}
.buy_code_text {
  padding: 23px 18px;
  font-size: 14px;
  font-weight: bold;
}
.buy_code_text span {
  font-weight: bold;
}
.buy_code_text span:nth-child(3) {
  margin-left: 53px;
}
.buy_code_text span.text_red {
  color: red;
  font-size: 16px;
  margin-left: 2px;
}
.buy_code_text span.samll {
  font-size: 13px;
}
.buy_use {
  background: red;
  padding: 0 7px;
  height: 17px;
  margin-top: 2px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  border-radius: 5px;
  position: relative;
  margin-left: 5px;
  line-height: 1.2;
  display: inline-block;
  cursor: pointer;
}
.buy_use::before {
  content: " ";
  position: absolute;
  left: -2px;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
  background: red;
  top: 4px;
}

.bg {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
  z-index: 90;
}
.coupon_wrap {
  width: 545px;
  height: 288px;
  background-color: #fff;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 91;
}
.coupon {
  position: relative;
}
.coupon h2 {
  height: 51px;
  line-height: 51px;
  font-size: 16px;
  font-weight: bold;
  color: #5957ff;
  text-align: center;
  border-bottom: 1px #e4e5e5 solid;
}
.close {
  position: absolute;
  top: 17px;
  right: 22px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  opacity: 0.6;
}
.close img {
  width: 16px;
  height: 16px;
}
.coupon_content {
  padding: 30px 0;
}
.coupon_content ul {
  overflow-y: auto;
  height: 180px;
}
.coupon_content li {
  width: 459px;
  height: 69px;
  border: 1px #e4e5e5 solid;
  border-radius: 10px;
  margin: auto;
  position: relative;
  margin-bottom: 20px;
}
.coupon_content li:last-child {
  margin-bottom: 0;
}
.coupon_content li::before {
  content: " ";
  width: 11px;
  height: 69px;
  background: #fce9d9;
  position: absolute;
  left: 0;
}
.price {
  float: left;
  margin-left: 30px;
  margin-top: 19px;
  color: red;
  margin-right: 33px;
}
.price span:nth-child(1) {
  font-size: 16px;
}
.price span:nth-child(2) {
  font-size: 30px;
}
.coupon_name {
  margin-top: 12px;
  float: left;
}
.coupon_name div:first-child {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: bold;
  color: #595757;
}
.coupon_name div:last-child {
  font-size: 12px;
  color: #8f8f8f;
}
.cut {
  position: absolute;
  left: 310px;
  top: -1px;
  width: 17px;
  height: 71px;
  background: url("../assets/images/vip/cut.png") no-repeat #fff;
}
.coupon_use {
  width: 102px;
  height: 32px;
  background: #fcebdd;
  text-align: center;
  line-height: 32px;
  font-size: 16px;
  border-radius: 10px;
  float: right;
  margin-top: 17px;
  margin-right: 19px;
  color: #ff3828;
  cursor: pointer;
}

.el-empty__image{width: 60px;}
.el-empty{padding: 25px 0;}
.wx_icon{ position: absolute; bottom: 20px; left: 190px;}
.wx_icon img{ width: 55px; height: 20px; margin: 0;}
.ali_icon{ position: absolute; bottom: 20px; left: 400px;}
.ali_icon img{ width: 69px; height: 20px;margin: 0;}
</style>
