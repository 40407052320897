<template>
  <div class="vip">
    <div class="banner">
      <img src="../assets/images/vip/bannercon.png" alt="" />
    </div>
    <div class="buys clearfix">
      <div class="top2">
        <div class="but">
          <img src="../assets/images/vip/tu3_new.png" />
          <button class="btn1" @click="toBuy(6)">
            <img src="../assets/images/vip/submit1.png" alt="" />
          </button>
          <button class="btn2" @click="toBuy(6)">
            <img src="../assets/images/vip/submit3.png" alt="" />
          </button>
          <button class="btn3" @click="toBuy(10)">
            <img src="../assets/images/vip/submit3.png" alt="" />
          </button>
        </div>
      </div>
      <div class="top3 clearfix">
        <img src="../assets/images/vip/tu3.png" />
      </div>
    </div>
    <div class="top4">
      <img src="../assets/images/vip/tu4.png" />
      <div class="top4_but">
        <button class="btn1" @click="toAgreement(5)"></button>
        <button class="btn2" @click="toAgreement(7)"></button>
      </div>
    </div>
    <div class="top5">
      <img src="../assets/images/vip/tu5.png" />
    </div>
    <!-- <div class="vip_buy" :class="isBuy? 'vip_buy_show':''" v-if="isBuy">  -->
    <el-drawer
      :visible.sync="isBuy"
      direction="ttb"
      :with-header="false"
      :destroy-on-close="true"
    >
      <buy
        :type="type"
        @typeFun="typeFun"
        @orderinfo="order_info_no"
        @toclearFun="clearIntervalFun"
      ></buy>
    </el-drawer>

    <el-drawer
      :visible.sync="isReceive"
      direction="ttb"
      :with-header="false"
      :destroy-on-close="true"
    >
      <Receive
        :type="Receivetype"
        :number="order_no"
        :tipnum="tipnum"
        @close="closeDrawer"
        @toNews="toNewsFun"
        @toIndexs="toIndex"
      ></Receive>
    </el-drawer>
    <div class="bg" v-if="tipStatus"></div>
    <div class="tips_wrap" v-show="tipStatus">
      <div class="tips">
        <div class="tips_thumb">
          <img src="../assets/images/redeem_success1.png" v-if="type == 6" />
          <img src="../assets/images/redeem_success2.png" v-if="type == 10" />
        </div>
        <div class="tips_h2">恭喜，购买成功</div>
        <div class="tips_p">可到我的主页查看</div>

        <div class="close" @click="tipStatusFun">
          <img src="../assets/images/close.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let Base64 = require('js-base64').Base64;
import buy from "../components/vipBuy.vue";
import Receive from "../components/receivingPopup.vue";
export default {
  name: "vip",
  components: {
    buy,
    Receive,
  },
  data() {
    return {
      isBuy: false,

      isReceive: false,
      info: {}, //介绍信息

      order_no: "", //订单编号
      setintrerval: "", //接口轮询

      type: 1,

      Receivetype: 1,

      problem: [], //常见问题
      tipnum: 0,
      tipStatus: false,
    };
  },
  mounted() {
    this.$parent.routerIndex = 0;
    if (localStorage.getItem("type")) {
      this.type = localStorage.getItem("type");
      localStorage.removeItem("type");
      this.isBuy = true;
    }
    if (window.location.href.indexOf('?')!=-1) {
      var url = window.location.href
      var invite = url.split('?')[1]
      var inviteId = Base64.decode(invite).split('=')[1];
      localStorage.setItem('inviteId', inviteId)
      this.toBuy(10)
    }
    
  },
  methods: {
    order_info_no(data) {
      this.order_no = data;
    },
    typeFun(type) {
      this.type = type;
    },
    // 会员介绍
    initFun() {
      var that = this;
      this.$api.POST(this.$face.member_perksIndex, "", function (res) {
        that.info = res.data;
      });
    },

    //购买会员
    toBuy(el) {
      if (localStorage.getItem("access_token")) {
        this.type = el;
        this.isBuy = true;

        if (!this.timer) {
          this.timer = window.setInterval(() => {
            //执行内容
            setTimeout(this.searchOrder(this.order_no), 0);
          }, 5000);
        }
      } else {
        this.$parent.loginShow();
      }
    },
    // 查询扫码支付
    searchOrder() {
      var that = this;
      var data = {
        order_no: that.order_no,
      };
      this.$api.POST(this.$face.member_orderPaytype, data, function (res) {
        console.log("查询扫码支付", res);
        if (res.data.is_pay == 1) {
          // 支付完成后收货地址
          if (that.timer) {
            window.clearInterval(that.timer);
            that.timer = null;
          }
          that.isBuy = false;
          if (res.data.is_exp == 0) {
            that.isReceive = true;
          } else {
            that.tipStatus = true;
        
          }
        }
      });
    },
	tipStatusFun(){
		this.tipStatus=false;
		this.$router.push({
        name: "index",
      });
	},
    closeDrawer(i) {
      // 支付
      this.isBuy = false;
      // 支付完成后收货地址
      this.isReceive = false;
      if (i == 1) {
        setTimeout(() => {
          this.$router.push({
            name: "index",
          });
        }, 1000);
      }
    },
    clearIntervalFun() {
      if (this.timer) {
        window.clearInterval(this.timer);
        this.timer = null;
      }
    },

    // linkFun
    linkFun() {
      document.querySelector("#vipBuy").scrollIntoView({
        behavior: "smooth", //带滚动效果
        block: "start", //上边框与顶部空间对齐 默认值
      });
    },

    toAgreement(id) {
      this.closeDrawer();
      // this.$router.push({
      // 	name: "userAgreement",
      // 	query: {
      // 		id: id
      // 	}
      // })
      let routeUrl = this.$router.resolve({
        name: "userAgreement",
        query: {
          id: id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    toIndex() {
      this.$router.push({
        name: "index",
      });
    },
    toNewsFun() {
      this.$parent.newsFun();
    },
  },
};
</script>

<style scoped>
.banner {
  height: 488px;
  width: 100%;
  background: url(../assets/images/vip/banner.png) top center no-repeat;
  background-size: cover;
}
.banner img {
  margin: auto;
}
.buys {
  background: #f6f6f6;
  height: 1046px;
  position: relative;
}

.top2 {
  position: absolute;
  top: -175px;
  z-index: 9;
  text-align: center;
  width: 100%;
}

.top2 img {
  margin: auto;
}

.top3 {
  position: absolute;
  top: 538px;
  width: 100%;
  text-align: center;
}

.top3 img {
  margin: auto;
}

.top4 {
  height: 1059px;
  width: 100%;
  background: #fde4d0;
}

.top4 img {
  margin: auto;
}

.top4_but {
  position: relative;
  width: 100%;
  height: 80px;
  overflow: hidden;
  margin: auto;
  bottom: 87px;
}

.top4_but button {
  width: 100px;
  height: 30px;
  border: none;
  background: none;
  position: absolute;
  cursor: pointer;
}

.top4_but .btn1 {
  left: 51%;
  bottom: 62px;
}

.top4_but .btn2 {
  left: 56.5%;
  bottom: 62px;
}

.top5 {
  height: 916px;
  width: 100%;
  background: #f6f6f6;
}

.top5 img {
  margin: 95px auto 0;
}

.but {
  position: relative;
  width: 1279px;
  margin: auto;
}

.but button {
  width: 329px;
  height: 62px;
  border: none;
  background: none;
  position: absolute;
  cursor: pointer;
  padding: 0;
}

.but .btn1 {
  left: 47px;
  top: 225px;
}
.but .btn1 img,
.but .btn2 img,
.but .btn3 img {
  transition: all 0.35s;
}
.but .btn1:hover img,
.but .btn2:hover img,
.but .btn3:hover img {
  transform: scale(1.04);
  transition: all 0.35s;
}

.but .btn2 {
  left: 476px;
  top: 225px;
}

.but .btn3 {
  left: 905px;
  top: 225px;
}

.vip >>> .el-drawer {
  height: 100% !important;
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0px rgb(0 0 0 / 0%),
    0 0px 0px 0px rgb(0 0 0 / 0%) !important;
}
.tips_wrap {
  background-color: #fff;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 91;
  width: 545px;
  height: 288px;
}
.tips {
  position: relative;
}
.tips_thumb {
  padding-top: 40px;
  width: 495rpx;
  margin: auto;
}
.tips_thumb img {
  display: block;
  margin: auto;
}
.tips_h2 {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: #595757;
  padding: 27px 0 10px;
}
.tips_p {
  font-size: 14px;
  color: #5e5c5c;
  text-align: center;
}
.close {
  position: absolute;
  right: 22px;
  top: 20px;
}
.close img {
  width: 15px;
  height: 15px;
  opacity: 0.6;
}
</style>
